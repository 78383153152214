import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {from, Observable} from 'rxjs';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {switchMap} from 'rxjs/operators';

@Injectable()
export class VersionInterceptor implements HttpInterceptor {

    constructor(
        private readonly appVersion: AppVersion
    ) {
    }

    /**
     * Used to add mobileApp params to avoid cors problem
     * @param req Request object
     * @param next Next handler
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return from(this.appVersion.getVersionNumber().catch(() => '')).pipe(
            switchMap(code => next.handle(req.clone({
                setHeaders: {
                    'app-version': code.toString(),
                    'api-version': '1.0'
                }
            })))
        );

    }

}
