import {Storage} from '@ionic/storage';
import {Injectable} from '@angular/core';
import {from, Observable, of} from 'rxjs';
import {IAddShopData, IShopData, ISignInByCredentials, IUserCredentials} from './data-shops.service.models';
import {catchError, map, mapTo, shareReplay, switchMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {IAuthenticateDto, ITechnicalDomainDto} from './data-shops.service.dto';
import {HttpStatusCode} from '../../../../constans/http-status-code.enum';
import {Router} from '@angular/router';
import {Omit} from 'utility-types';
import {DataShopsService as DataShopsMockService} from './data-shops.service.mock';
import {MockedService} from '../../mocked-service';

@Injectable({
    providedIn: 'root'
})
export class DataShopsService implements MockedService<DataShopsMockService> {

    // Service data
    private readonly MAX_ID_STORAGE: string;
    private readonly SHOPS_STORAGE: string;

    constructor(
        private readonly storage: Storage,
        private readonly httpClient: HttpClient,
        private readonly router: Router,
    ) {

        // Set storage key
        this.MAX_ID_STORAGE = 'max-id';
        this.SHOPS_STORAGE = 'shop-list';

    }

    /**
     * Generates shop id
     */
    private get uniqueShopId(): Observable<number> {

        return from(this.storage.get(this.MAX_ID_STORAGE)).pipe(
            map(v => Number.isNaN(+v) ? 1 : ++v),
            switchMap(maxId => from(this.storage.set(this.MAX_ID_STORAGE, maxId)).pipe(
                map(() => maxId)
            ))
        );

    }

    /**
     * Gets all saved shops in device
     */
    public getShopsList(): Observable<IShopData[]> {

        return from(this.storage.get(this.SHOPS_STORAGE)).pipe(
            map(v => Array.isArray(v) ? v : []),
            map((v: IShopData[]) => v.sort((p, c) => p.id - c.id))
        );

    }

    /**
     * Gets shop server origin and display name based on given web address
     * @param domain Shop's domain address
     */
    private getShopBasicData(domain: string): Observable<Pick<IShopData, 'serverOrigin' | 'displayName'>> {

        // Getting display name from given domain
        let displayName: string = domain.trim();
        displayName = displayName.replace('https://', '');
        displayName = displayName.replace('http://', '');
        displayName = displayName.replace('www.', '');

        // Getting technical domain
        const technicalDomain$: Observable<string> = this.httpClient.post<ITechnicalDomainDto>(
            'https://konto.sky-shop.pl/api_public/',
            null,
            {
                params: {
                    function: 'getTechDomain',
                    domain: displayName,
                    hash: 'SouWas1Qua'
                }
            }).pipe(
            map(v => v.url),
            catchError(() => of(null)),
        );

        // Return data
        return technicalDomain$.pipe(map(serverOrigin => serverOrigin !== null
            ? {serverOrigin, displayName}
            : null
        ));

    }

    /**
     * Adds new shop to list
     * @param domain Shop server origin
     */
    public addShop(domain: string): Observable<IAddShopData> {

        // Getting shop technical domain
        const technicalDomain$ = this.getShopBasicData(domain).pipe(
            shareReplay()
        );

        // Add shop observable
        const addShop$: Observable<IShopData> = technicalDomain$.pipe(
            switchMap(({serverOrigin, displayName}) => this.uniqueShopId.pipe(
                switchMap(id => this.getShopsList().pipe(
                    map(v => v.concat({id, displayName, serverOrigin})),
                    switchMap(list => from(this.storage.set(this.SHOPS_STORAGE, list)).pipe(
                        map(v => list[list.length - 1])
                    )),
                )),
                catchError(() => of(null))
            ))
        );

        // Return response
        return technicalDomain$.pipe(
            switchMap((technicalDomain): Observable<IAddShopData> => technicalDomain !== null
                ? addShop$.pipe(map(v => ({addedShop: v})))
                : of({addedShop: null, invalidServerOrigin: true})
            ),
            catchError((): Observable<IAddShopData> =>
                of({addedShop: null, invalidServerOrigin: false})
            ),
        );

    }

    /**
     * Removes shop from list
     * @param shopId Shop id to remove
     */
    public removeShop(shopId: number): Observable<boolean> {

        return this.getShopsList().pipe(
            map(v => { // check if shop exists
                if (v.find(s => s.id === shopId) === undefined) {
                    throw new Error('Unable to find shop');
                } else {
                    return v;
                }
            }),
            map(v => v.filter(s => s.id !== shopId)),
            switchMap(updatedList => from(this.storage.set(this.SHOPS_STORAGE, updatedList))),
            map(p => true),
            catchError(() => of(false))
        );

    }

    /**
     * Updates shop data
     */
    private updateShop(...shops: Array<Partial<Omit<IShopData, 'id'>> & Pick<IShopData, 'id'>>): Observable<void> {

        // Updating shops
        const updatedShops$: Observable<IShopData[]> = this.getShopsList().pipe(
            map(shopList => shopList.map(target => {
                const updatedData: Partial<IShopData> = shops.find(s => s.id === target.id);
                if (updatedData) {
                    return Object.assign({}, target, updatedData);
                } else {
                    return target;
                }
            }))
        );

        // Return
        return updatedShops$.pipe(
            switchMap(updatedList => from(this.storage.set(this.SHOPS_STORAGE, updatedList)))
        );

    }

    /**
     * Sign in user to shop
     * @param user User to sign in
     * @param shopId Shop id
     */
    public signInToShopByCredentials(user: IUserCredentials, shopId: number): Observable<ISignInByCredentials> {

        // Getting shop data
        const shopData$: Observable<IShopData> = this.getShopsList().pipe(
            map(v => v.find(s => s.id === shopId))
        );

        // Create form data
        const formData = new FormData();
        formData.append('email', user.email);
        formData.append('password', user.password);
        formData.append('token_type', 'app_mobile');

        // Get authentication token and sign in
        const signIn$: Observable<ISignInByCredentials> = this.httpClient.post<IAuthenticateDto>('/api', formData, {
            params: {
                function: 'authenticate',
            }
        }).pipe(switchMap(({response_code, access_token}) => {

            // Depending on response
            if (response_code === HttpStatusCode.OK) { // success
                return this.updateShop({id: shopId, authenticationToken: access_token, userEmail: user.email}).pipe(
                    mapTo({success: true})
                );
            } else if (response_code === HttpStatusCode.FORBIDDEN) { // no privileges, lock or deactivated account
                return of({success: false, forbidden: true});
            } else if (response_code === HttpStatusCode.UNAUTHORIZED) { // invalid credential
                return of({success: false, invalidCredentials: true});
            } else { // other error
                return of({success: false});
            }

        }));

        // Return
        return shopData$.pipe(
            switchMap(shopData => typeof shopData.serverOrigin === 'string' // check is server origin exists
                ? signIn$ // if exists try to sign in
                : this.getShopBasicData(shopData.displayName).pipe(switchMap(basicData => basicData !== null // if not getting base data
                    ? this.updateShop({ // update shop
                        id: shopId,
                        displayName: basicData.displayName,
                        serverOrigin: basicData.serverOrigin
                    }).pipe(switchMap(() => signIn$)) // and sign in
                    : of({success: false, unableToReloadServerOrigin: false})
                ))
            )
        );

    }

    /**
     * Sign in user to shop
     * @param token Authentication token
     * @param shopId Shop id
     */
    public signInToShopByAuthenticationToken(token: string, shopId: number): Observable<void> {

        return this.updateShop({id: shopId, authenticationToken: token});

    }

    /**
     * Sign out from shop
     * @param shopId Shop id
     */
    public signOutFromShop(shopId: number): Observable<void> {

        return this.updateShop({id: shopId, authenticationToken: null});

    }

    /**
     * Signs out fromm all shops
     * @param clearServerOrigin Clears server origins if need
     */
    public signOutFromAllShops(clearServerOrigin: boolean = false): Observable<void> {

        // Create updated shops observable
        const updatedShops$: Observable<IShopData[]> = this.getShopsList().pipe(map(shopsData => shopsData.map(v => Object.assign(v, {
            authenticationToken: null,
            serverOrigin: clearServerOrigin ? null : v.serverOrigin
        }))));

        // Update all
        return updatedShops$.pipe(
            switchMap(updatedShops => this.updateShop(...updatedShops))
        );

    }

    /**
     * Gets shop id from url
     * @param url Optional url to extract data
     */
    public getShopIdFromUrl(url: string = this.router.url): number {

        // Extract url
        const urlWithoutParams: string = url.split('?')[0];

        // Checking math
        if (urlWithoutParams.match(new RegExp('/main-view/[0-9]+'))) {
            const [match] = urlWithoutParams.match(new RegExp('/main-view/[0-9]+'));
            const [split, shopId] = match.split('/main-view/');
            return +shopId;
        } else if (urlWithoutParams.match(new RegExp('/sign-in-view/sign-in/[0-9]+'))) {
            const [match] = urlWithoutParams.match(new RegExp('/sign-in-view/sign-in/[0-9]+'));
            const [split, shopId] = match.split('/sign-in-view/sign-in/');
            return +shopId;
        } else {
            return null;
        }

    }

    /**
     * Gets shop data based on shopId in url
     * @param url Optional url to extract data
     */
    public getShopFromUrl(url: string = this.router.url): Observable<IShopData> {

        // Get shop id
        const shopId: number = this.getShopIdFromUrl(url);

        // Return statement
        return this.getShopsList().pipe(
            map(v => v.find(s => s.id === shopId))
        );

    }

}
