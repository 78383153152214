import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HttpStatusCode} from '../../../constans/http-status-code.enum';
import {AlertController, LoadingController, NavController} from '@ionic/angular';
import {DataShopsService} from '../../shared-utilities/services/data-shops/data-shops.service';
import {CanDeactivateGuard} from '../../main-view-guards/can-deactivate/can-deactivate.guard';

@Injectable()
export class UnknownErrorInterceptor implements HttpInterceptor {

    // Interceptor data
    private isUnknownErrorAlertVisible: boolean;

    constructor(
        private readonly alertController: AlertController,
        private readonly dataShopsService: DataShopsService,
        private readonly loadingController: LoadingController,
        private readonly navController: NavController,
    ) {
    }

    /**
     * Used to catch all unknown app error response
     * @param req Request object
     * @param next Next handler
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req.clone()).pipe(catchError((error: HttpErrorResponse) => {

            // None unknown error
            if (error.status !== HttpStatusCode.UNKNOWN) {
                return throwError(error);
            }

            // Handle unknown error
            return this.handleUnknownError();

        }));

    }

    /**
     * Handles unknown server errors
     */
    private async handleUnknownError(): Promise<null> {

        // Alert is already visible
        if (this.isUnknownErrorAlertVisible) {
            return null;
        }

        // Set is visible to true
        this.isUnknownErrorAlertVisible = true;

        // Close all current alerts and loadings
        await Promise.all([
            this.loadingController.dismiss().catch(() => null),
            this.alertController.dismiss().catch(() => null)
        ]);

        // Sign out from current shop
        const currentShopId: number = this.dataShopsService.getShopIdFromUrl();
        await this.dataShopsService.signOutFromShop(currentShopId).toPromise();

        // Navigate to sign in and create alert
        const navigateRoot = currentShopId !== null
            ? `/sign-in-view/sign-in/${currentShopId}?${CanDeactivateGuard.PARAM_FORCE_DEACTIVATION}=1`
            : `/sign-in-view/shop-list?${CanDeactivateGuard.PARAM_FORCE_DEACTIVATION}=1`;
        const [, alert] = await Promise.all([
            this.navController.navigateRoot(navigateRoot),
            this.alertController.create({
                header: 'Połączenie z serwerem',
                message: `
                    Z nieznanych przyczyn nie udało się połączyć z&nbsp;serwerem. Spróbuj ponownie
                    za parę minut lub jeżeli błąd będzie nadal występował dodaj sklep
                    na nowo.
                `,
                backdropDismiss: false,
                buttons: ['OK']
            }),
        ]);

        // On alert close
        alert.onWillDismiss().then(() => this.isUnknownErrorAlertVisible = false);

        // Show alert
        await alert.present();

        // Return
        return null;

    }

}
