import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class CorsInterceptor implements HttpInterceptor {

    constructor() {
    }

    /**
     * Used to add mobileApp params to avoid cors problem
     * @param req Request object
     * @param next Next handler
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req.clone({
            setParams: {
                mobileApp: '1'
            }
        }));

    }

}
