import {Component} from '@angular/core';

import {MenuController, Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {AppMinimize} from '@ionic-native/app-minimize/ngx';
import {MobileAccessibility} from '@ionic-native/mobile-accessibility/ngx';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {

    constructor(
        private readonly platform: Platform,
        private readonly splashScreen: SplashScreen,
        private readonly statusBar: StatusBar,
        private readonly appMinimize: AppMinimize,
        private readonly menuController: MenuController,
        private readonly mobileAccessibility: MobileAccessibility
    ) {
        this.initializeApp();
    }

    initializeApp() {

        this.platform.ready().then(() => {
            this.statusBar.hide();
            this.splashScreen.hide();
            this.mobileAccessibility.usePreferredTextZoom(false);
        });

        this.platform.backButton.subscribeWithPriority(1, async () => {
            if (!(await this.menuController.isOpen())) {
                await this.appMinimize.minimize();
            }
        });

    }

}
