import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {DataShopsService} from '../../shared-utilities/services/data-shops/data-shops.service';
import {catchError, map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CanActivateGuard implements CanActivate {

    constructor(
        private readonly dataShopsService: DataShopsService,
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

        // Can activate if has valid token
        return this.dataShopsService.getShopFromUrl(state.url).pipe(
            map(v => v.authenticationToken),
            map(authenticationToken => typeof authenticationToken === 'string'),
            catchError(() => of(false))
        );

    }

}
