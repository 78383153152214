import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HttpStatusCode} from '../../../constans/http-status-code.enum';
import {AlertController, LoadingController, NavController} from '@ionic/angular';
import {DataShopsService} from '../../shared-utilities/services/data-shops/data-shops.service';
import {CanDeactivateGuard} from '../../main-view-guards/can-deactivate/can-deactivate.guard';

@Injectable()
export class UnauthorisedInterceptor implements HttpInterceptor {

    // Interceptor data
    private isUnauthorisedAlertVisible: boolean;

    constructor(
        private readonly navController: NavController,
        private readonly alertController: AlertController,
        private readonly dataShopsService: DataShopsService,
        private readonly loadingController: LoadingController,
    ) {

        // Set is visible to false
        this.isUnauthorisedAlertVisible = false;

    }

    /**
     * Used to catch all unauthorised requests
     * @param req Request object
     * @param next Next handler
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req.clone()).pipe(catchError((error: HttpErrorResponse) => {

            // None unauthorised error
            if (error.status !== HttpStatusCode.UNAUTHORIZED) {
                return throwError(error);
            }

            // Handle unauthorised
            return this.handleUnauthorisedError();

        }));

    }

    /**
     * Handles unauthorized errors
     */
    private async handleUnauthorisedError(): Promise<null> {

        // Alert is already visible
        if (this.isUnauthorisedAlertVisible) {
            return null;
        }

        // Set is visible to true
        this.isUnauthorisedAlertVisible = true;

        // Close all current alerts and loadings
        await Promise.all([
            this.loadingController.dismiss().catch(() => null),
            this.alertController.dismiss().catch(() => null)
        ]);

        // Sign out from current shop
        const currentShopId: number = this.dataShopsService.getShopIdFromUrl();
        await this.dataShopsService.signOutFromShop(currentShopId).toPromise();

        // Navigate to sign in and create alert
        const navigateRoot = currentShopId !== null
            ? `/sign-in-view/sign-in/${currentShopId}?${CanDeactivateGuard.PARAM_FORCE_DEACTIVATION}=1`
            : `/sign-in-view/shop-list?${CanDeactivateGuard.PARAM_FORCE_DEACTIVATION}=1`;
        const [, alert] = await Promise.all([
            this.navController.navigateRoot(navigateRoot),
            this.alertController.create({
                header: 'Błąd pobierania',
                message: 'Nieautoryzowana próba dostępu',
                backdropDismiss: false,
                buttons: ['OK']
            }),
        ]);

        // On alert close
        alert.onWillDismiss().then(() => this.isUnauthorisedAlertVisible = false);

        // Show alert
        await alert.present();

        // Return
        return null;

    }

}
