import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanDeactivate,
    Router,
    RouterStateSnapshot,
    UrlSerializer,
    UrlTree
} from '@angular/router';
import {Location} from '@angular/common';
import {AlertController} from '@ionic/angular';
import {Observable, Subscriber} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CanDeactivateGuard<T extends { hasSavedData: boolean; canDeactivate: any }> implements CanDeactivate<T> {

    // Guard data
    public static readonly PARAM_FORCE_DEACTIVATION = 'DEACTIVATE_GUARD_FORCE_DEACTIVATION';
    public static readonly ALLOWED_SUB_ROUTES_DATA: string = 'DEACTIVATE_GUARD_ALLOWED_SUB_ROUTES';
    private readonly subRoutesLocations: Map<string, {
        parentUrl: string;
        parentDataSaved: boolean;
    }>;

    constructor(
        private readonly location: Location,
        private readonly router: Router,
        private readonly alertController: AlertController,
        private readonly urlSerializer: UrlSerializer,
    ) {

        this.subRoutesLocations = new Map();

    }

    canDeactivate(
        component: T,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        // Check if force deactivation
        if (this.urlSerializer.parse(nextState.url).queryParamMap.has(CanDeactivateGuard.PARAM_FORCE_DEACTIVATION)) {
            return true;
        }

        // Check if next route is allowed sub route
        const subRoute = nextState.url.split(`${currentState.url}/`)[1];
        const allowedSubRoutes: string[] = currentRoute.data[CanDeactivateGuard.ALLOWED_SUB_ROUTES_DATA] || [];
        if (allowedSubRoutes.includes(subRoute)) {
            this.subRoutesLocations.set(nextState.url, {
                parentUrl: currentState.url,
                parentDataSaved: component.hasSavedData,
            });
            return true;
        }

        // Child route can back only to parent if parent was left with saved data
        const subRouteLocation = this.subRoutesLocations.get(currentState.url);
        if (
            subRouteLocation && subRouteLocation.parentDataSaved || // parent has saved data
            subRouteLocation && subRouteLocation.parentUrl === nextState.url // back to parent
        ) {
            this.subRoutesLocations.delete(currentState.url);
            return true;
        }

        // Can deactivate if has saved data
        if (component.hasSavedData) {
            return true;
        }

        // Otherwise show alert
        return this.showUnsavedDataAlert(component, currentState);

    }

    /**
     * Shows alert with unsaved data
     * Extra parameters passed to solve problem
     * https://github.com/angular/angular/issues/13586
     */
    private showUnsavedDataAlert(component: T, currentState: RouterStateSnapshot): Observable<boolean> {

        // Create no handler
        const noHandler = (resolver: Subscriber<false>) => {
            if (!component.canDeactivate && this.router.getCurrentNavigation().trigger === 'popstate') {
                this.location.go(currentState.url);
            }
            resolver.next(false);
        };

        // Create yes handler
        const yesHandler = (resolver: Subscriber<true>) => resolver.next(true);

        // Return observable with alert
        return new Observable<boolean>(resolver => {

            // Show alert
            const alert = this.alertController.create({
                header: 'Niezapisane zmiany',
                message: `Posiadasz niezapisane zmiany na bieżącym widoku.<br/>Czy na pewno chcesz opuścić?`,
                backdropDismiss: false,
                buttons: [
                    {
                        text: 'Nie',
                        handler: () => noHandler(resolver),
                    },
                    {
                        text: 'Tak',
                        handler: () => yesHandler(resolver),
                    },
                ]
            });
            alert.then(r => r.present());

            // Unsubscribe
            return () => alert.then(r => r.remove());

        });

    }

}
