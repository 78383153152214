import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {DataShopsService} from '../../shared-utilities/services/data-shops/data-shops.service';
import {Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

@Injectable()
export class ServerOriginInterceptor implements HttpInterceptor {

    constructor(
        private readonly dataShopsService: DataShopsService,
    ) {
    }

    /**
     * Used to add server origin to request url
     * @param req Request object
     * @param next Next handler
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // Getting server origin
        const serverOrigin$: Observable<string> = this.dataShopsService.getShopFromUrl().pipe(
            map(v => v && v.serverOrigin ? v.serverOrigin : '')
        );

        // Return with server origin
        return serverOrigin$.pipe(
            map(origin => req.clone({url: `${origin}${req.url}`})),
            switchMap(request => next.handle(request))
        );

    }

}
