import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {from, Observable, of, throwError} from 'rxjs';
import {Connection, Network} from '@ionic-native/network/ngx';
import {AlertController, LoadingController} from '@ionic/angular';
import {concatMap, delay, filter, finalize, mapTo, retryWhen, switchMap} from 'rxjs/operators';
import {HttpStatusCode} from '../../../constans/http-status-code.enum';

@Injectable()
export class OfflineInterceptor implements HttpInterceptor {

    // Interceptor data
    private offlineAlertElement: HTMLIonAlertElement;

    constructor(
        private readonly network: Network,
        private readonly alertController: AlertController,
        private readonly loadingController: LoadingController,
    ) {
    }

    /**
     * Used to catch offline requests
     * @param req Request object
     * @param next Next handler
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req.clone()).pipe(retryWhen(errors => errors.pipe(
            concatMap((error: HttpErrorResponse, index) => {

                // Depending on network and error status
                if (this.isOffline) { // is offline
                    return from(this.showOfflineAlertMessage('Oczekiwanie na wznowienie dostępu do internetu.')).pipe(
                        switchMap(() => from(new Promise(resolve => { // retry when online
                            const networkOnChange = this.network.onChange().pipe(
                                filter((value: Event) => value.type === 'online')
                            ).subscribe(() => {
                                networkOnChange.unsubscribe();
                                resolve();
                            });
                        }))),
                        switchMap(() => this.dismissOfflineAlertMessage()), // dismiss alert
                        mapTo(error)
                    );
                } else if (error.status === HttpStatusCode.UNKNOWN) { // is online but status is unknown
                    return from(this.showOfflineAlertMessage('Próba ponownego nawiązania połączenia z&nbsp;serwerem.')).pipe(
                        delay(2000),
                        mapTo(error)
                    );
                } else { // is online and status is NOT unknown
                    return throwError(error);
                }

            }),
            concatMap((error, index) => index < 2
                ? of(null)
                : throwError(error)
            ),
            finalize(() => from(this.dismissOfflineAlertMessage()))
        )));

    }

    /**
     * Checks if internet connection is available
     */
    private get isOffline(): boolean {

        const type = (this.network.type || '').toUpperCase();
        return type === Connection[Connection.NONE];

    }

    /**
     * Shows alert message
     * @param textContent Message to show
     */
    private async showOfflineAlertMessage(textContent: string): Promise<void> {

        // Alert is visible but content is different
        if (this.offlineAlertElement && this.offlineAlertElement.message !== textContent) {
            this.offlineAlertElement.message = textContent;
            return;
        }

        // Alert is visible and content the same
        if (this.offlineAlertElement && this.offlineAlertElement.message === textContent) {
            return;
        }

        // Other alert is in progress
        if (this.offlineAlertElement === null) {
            return;
        }

        // Set alert in progress
        this.offlineAlertElement = null;

        // Getting top alert, top loading and create alert
        const [currentAlert, currentLoading, alert] = await Promise.all([
            this.alertController.getTop(),
            this.loadingController.getTop(),
            this.alertController.create({ // create alert
                header: 'Bład połączenia',
                message: textContent,
                backdropDismiss: false,
            })
        ]);

        // Hide current alert
        if (currentAlert) {
            currentAlert.style.display = 'none';
            alert.onDidDismiss().then(() => currentAlert.style.display = 'flex');
        }

        // Hide current loading
        if (currentLoading) {
            currentLoading.style.display = 'none';
            alert.onDidDismiss().then(() => currentLoading.style.display = 'flex');
        }

        // Show alert
        await alert.present();

        // Assign alert
        this.offlineAlertElement = alert;

    }

    /**
     * Hides alert
     */
    private async dismissOfflineAlertMessage(): Promise<void> {

        if (this.offlineAlertElement) {
            await this.offlineAlertElement.dismiss();
        }
        this.offlineAlertElement = undefined;

    }

}
