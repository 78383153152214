import {Animation} from '@ionic/core';

/**
 * Empty page-to-page transitions
 */
export function appRoutingAnimation(AnimationC: Animation, baseEl: any, opts?: any): Promise<Animation> {

    return Promise.resolve(new AnimationC());

}
