import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

    // Interceptor data
    public static readonly PARAM_TIME: string = `cacheTime${Math.random()}`;
    public static readonly PARAM_GROUP: string = `cacheId${Math.random()}`;
    private readonly cache: Map<string, {
        response: HttpResponse<any>;
        saveDate: number;
        cacheTime: number;
    }>;

    constructor() {

        this.cache = new Map();

    }

    /**
     * Used to cache requests
     * @param req Request object
     * @param next Next handler
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // Extract cache time
        const cacheTime: number = req.params.has(CacheInterceptor.PARAM_TIME)
            ? +req.params.get(CacheInterceptor.PARAM_TIME).replace(new RegExp('_', 'g'), '')
            : null;

        // Remove cache params
        const requestWithoutCacheParams = req.clone({
            params: req.params
                .delete(CacheInterceptor.PARAM_TIME)
                .delete(CacheInterceptor.PARAM_GROUP)
        });

        // Create cache id
        const cacheId = `${requestWithoutCacheParams.urlWithParams}/${req.params.get(CacheInterceptor.PARAM_GROUP)}`;

        // Read from cache or send request
        return this.getCache(cacheId).pipe( // checking cache
            switchMap(cachedResponse => cachedResponse
                ? of(cachedResponse) // if cache exists
                : next.handle(requestWithoutCacheParams) // otherwise send request
            )
        ).pipe(switchMap(event => event instanceof HttpResponse && cacheTime
            ? this.setCache(cacheId, event, cacheTime)
            : of(event)
        ));

    }

    /**
     * Gets request cache
     * @param cacheId Id of cache
     */
    private getCache(cacheId: string): Observable<HttpResponse<any>> {

        this.reloadCache();
        const cached = this.cache.get(cacheId);
        return of(cached ? cached.response : null);

    }

    /**
     * Sets request's response to cache
     * @param cacheId Cache id
     * @param response Response to cache
     * @param cacheTime Cache time in ms
     */
    private setCache(cacheId: string, response: HttpResponse<any>, cacheTime: number): Observable<HttpResponse<any>> {

        this.reloadCache();
        const entry = {response, cacheTime, saveDate: new Date().valueOf()};
        this.cache.set(cacheId, entry);
        return of(response);

    }

    /**
     * Deletes expired cache
     */
    private reloadCache(): Observable<void> {

        Array.from(this.cache.keys())
            .filter(key => this.cache.get(key).saveDate < new Date().valueOf() - this.cache.get(key).cacheTime)
            .forEach(key => this.cache.delete(key));
        return of();

    }


}


